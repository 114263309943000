<template>
  <div>
    <page-toolbar title="Edit Module"></page-toolbar>
    <v-container>
      <page-title title="Edit Module">
        <template slot="action">
          <v-btn @click="goBack()" class="mx-1">Back</v-btn>
        </template>
      </page-title>
      <v-card :loading="loading">
        <v-toolbar dense elevation="0">
          <v-toolbar-title>Edit Perm Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showAddDialog()" title="Add">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="6">
              <v-text-field readonly flat outlined dense hide-details v-model="formData.id" label="ID"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field readonly flat outlined dense hide-details v-model="formData.name" label="Name"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field readonly flat outlined dense hide-details v-model="formData.description" label="Description"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field readonly flat outlined dense hide-details v-model="formData.is_active" label="Is Active"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>


        <v-card flat elevation="0">
          <v-toolbar dense elevation="0">
            <div style="max-width: 100px">
              <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="datatable_options.itemsPerPage" style="max-width: 100px" hide-details single-line></v-select>
            </div>

            <v-spacer></v-spacer>

            <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

            <v-btn icon @click="getData(true)" title="Refresh">
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-2" v-if="isMobile">
            <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
          </div>

          <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event">
            <template v-slot:[`item.action`]="{ item }" v-if="!isMobile">
              <v-btn icon text x-small class="mx-1" color="primary" @click="deleteData(item)"><v-icon>mdi-delete</v-icon></v-btn>
            </template>

            <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>

          </v-data-table>
          <div class="text-right pt-2 mx-auto" style="max-width: 600px">
            <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
          </div>
        </v-card>
      </v-card>
    </v-container>
    <module-add-perm ref="refModuleAddPerm"></module-add-perm>
  </div>
</template>

<script>
import ModuleAddPerm from './components/ModuleAddPerm.vue';
export default {
  components: { ModuleAddPerm },
  name: 'module-edit',
  data() {
    return {
      moduleId: null,
      formData: {},

      dialog_detail: null,
      loading: null,
      search: "",
      datatable_options: {},
      itemsPerPageOption: [10, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "ID", value: "id", class: "text-no-wrap" },
          { text: "Name", value: "username", class: "text-no-wrap" },
          { text: "Perm Name", value: "perm_name", class: "text-no-wrap" },
          { text: "#", value: "action", name: "action", class: "text-no-wrap", searchable: false, sortable: false },
        ],
        data: [],
      },

      selectedItem: {},
    };
  },
  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.moduleId = this.$route.query.id;
      this.getModuleDetail();

      this.getData(true);

    },
    async getModuleDetail() {
      this.loading = true;
      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.moduleId,
        },
        cacheConfig: false,
      };
      await this.$axios
        .get("module/detail", config)
        .then((res) => {
          this.formData = res.data.data.module;

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    refreshData() {
      this.getData(true);
    },
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("POST", "module/data-users");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      var formData = new FormData();
      formData.append("search", this.search);
      formData.append("sort_by", sortBy);
      formData.append("sort_desc", sortDesc);
      formData.append("page", page);
      formData.append("limit", itemsPerPage);

      formData.append("module_id", this.moduleId);

      this.$axios
        .post("module/data-users", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    showAddDialog() {
      this.$refs.refModuleAddPerm.show(this.formData.id, () => {
        this.getData(true)
      });
    },
    async deleteData(item) {

      const post = async () => {
        this.loading = true;

        const uri = 'module/delete-user-perm';
        const formData = new FormData();
        formData.append("id", item.id);
        await this.$axios.post(uri, formData)
          .then((res) => {
            const resData = res.data;
            if (resData.status == 'success') {
              this.showAlert(resData.status, resData.message);
            } else {
              this.showAlert(resData.status, resData.message);
            }

            this.loading = false;

            this.getData(true);
          })
          .catch((e) => {
            this.loading = false;

            this.getData(true);

            this.axiosErrorHandler(e);
          });
      }

      this.showConfirm('Confirm', 'Delete Permission?', post);
      
      
    },
  },
  mounted() {
    this.load();
  }
}
</script>